import { useEffect, useState } from "react";
import "../styles/Form.scss";
import { useForm } from "react-hook-form";
import axios from "axios";

function Form({ setCurrentPage, setFormData }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [openFormEvent, setOpenFormEvent] = useState(false);
  const [isMustField, setIsMustField] = useState(false);
  const [isOpenPopup, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  var tomorrowDate = new Date();
  var dd = tomorrowDate.getDate() + 1;
  var mm = tomorrowDate.getMonth() + 1;
  var yyyy = tomorrowDate.getFullYear();
  //
  var hh = tomorrowDate.getHours();
  var m = tomorrowDate.getMinutes();

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  tomorrowDate = yyyy + "-" + mm + "-" + dd + "T" + hh + ":" + m;

  function handleFormEvent(v) {
    setOpenFormEvent(v);
    setIsMustField(v);
  }

  function handlePopupEvent(v) {
    setIsOpen(v);
  }

  function handleDobuleConfirmSubmitEvent() {
    if (Object.keys(errors).length === 0) {
      handlePopupEvent(true);
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      console.log("This will run after 1 second!");
    }, 3000);
    return () => clearTimeout(timer);
  }, [isLoading]);

  function onSubmit(data) {
    setIsLoading(true);
    console.log(data);
    let dataTimeA;
    let dataTimeB;
    let dataTimeC;
    let fulled;
    console.log(typeof data.eventIsFull);
    if (openFormEvent) {
      dataTimeA = convertDateFormat(data.eventDateTimeA);
      dataTimeB = convertDateFormat(data.eventDateTimeB);
      dataTimeC = convertDateFormat(data.eventDateTimeC);
      if (data.eventIsFull === "true") {
        fulled = true;
        console.log(fulled);
      } else if (data.eventIsFull === "false") {
        fulled = false;
        console.log(fulled);
      }
    } else {
      dataTimeA = null;
      dataTimeB = null;
      dataTimeC = null;
    }

    const postData = {
      school_name: data.schoolName ?? null,
      address: data.schoolAddress ?? null,
      event: data.event ?? null,
      student_year: data.studentYear ?? null,
      gift_pack_count: data.giftCount ?? null,
      prefered_a_at: dataTimeA ?? null,
      prefered_b_at: dataTimeB ?? null,
      prefered_c_at: dataTimeC ?? null,
      teacher_name: data.teacherName ?? null,
      teacher_email: data.teacherEmail ?? null,
      teacher_number: data.teacherPhoneNumber ?? null,
    };
    if (data.studentCount) {
      postData.student_count = data.studentCount ?? null;
    }
    if (fulled != null) {
      postData.further_action_on_full = fulled;
    }
    console.log(postData);

    axios
      .post("./api/form", postData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          setFormData((prevState) => ({
            ...prevState,
            schoolName: data.schoolName,
            teacherName: data.teacherName,
            studentYear: data.studentYear,
            studentCount: data.studentCount,
            giftCount: data.giftCount,
            event: data.event,
            eventDateTimeA: data.eventDateTimeA,
            code: res.data.code,
          }));
          setCurrentPage(1);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  console.log(errors);

  function convertDateFormat(inputDate) {
    const formattedDate = inputDate.replace("T", " ");
    return formattedDate;
  }

  return (
    <div className="form-page-container">
      <div className="fields-marked">
        所有 <span className="red-start">*</span> 項目必須填寫
      </div>

      <form className="form-container">
        {/**學校名稱  學校地址*/}
        <div className="input-row">
          <div className="input-container">
            <div className="input-label-container">
              <div className="input-label">
                <span className="red-start">*</span>學校名稱
              </div>
            </div>
            <input
              className="input-box"
              type="text"
              {...register("schoolName", { required: true })}
            />
            {errors.schoolName && (
              <span className="input-error-message">*請輸入有效學校名稱</span>
            )}
          </div>
          <div className="input-container">
            <div className="input-label-container">
              <div className="input-label">
                <span className="red-start">*</span>學校地址
              </div>
            </div>
            <input
              className="input-box"
              type="text"
              {...register("schoolAddress", { required: true })}
            />
            {errors.schoolAddress && (
              <span className="input-error-message">*請輸入有效學校地址</span>
            )}
          </div>
        </div>
        {/**老師全名  老師聯絡電郵*/}
        <div className="input-row">
          <div className="input-container">
            <div className="input-label-container">
              <div className="input-label">
                <span className="red-start">*</span>老師全名
              </div>
            </div>
            <input
              className="input-box"
              type="text"
              {...register("teacherName", {
                required: true,
                minLength: 2,
              })}
            />
            {errors.teacherName && (
              <span className="input-error-message">*請輸入有效老師全名</span>
            )}
          </div>
          <div className="input-container">
            <div className="input-label-container">
              <div className="input-label">
                <span className="red-start">*</span>老師聯絡電郵
              </div>
            </div>
            <input
              className="input-box"
              type="email"
              {...register("teacherEmail", {
                required: true,
                pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
              })}
            />
            {errors.teacherEmail && (
              <span className="input-error-message">
                *請輸入有效老師聯絡電郵
              </span>
            )}
          </div>
        </div>
        {/**老師聯絡電話*/}
        <div className="input-row">
          <div className="input-container">
            <div className="input-label-container">
              <div className="input-label">
                <span className="red-start">*</span>老師聯絡電話
              </div>
            </div>
            <input
              className="input-box"
              type="phone"
              onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
              {...register("teacherPhoneNumber", {
                required: true,
                minLength: 8,
                maxLength: 8,
                pattern: /^[5-9]([0-9]{7})$/,
              })}
            />
            {errors.teacherPhoneNumber && (
              <span className="input-error-message">
                *請輸入有效老師聯絡電話
              </span>
            )}
          </div>
          <div className="input-container"></div>
        </div>
        {/**參加學生年級 禮品包派發數量*/}

        <div className="input-row">
          <div className="input-container">
            <div className="input-label-container">
              <div className="input-label">
                <span className="red-start">*</span>參加學生年級
              </div>
            </div>
            <span>
              <label htmlFor="學生講座+護理包派發">
                <input
                  {...register("studentYear", { required: true })}
                  type="radio"
                  value="1"
                  name="studentYear"
                />
                小五<span style={{ marginRight: "20px" }}></span>
              </label>
              <label htmlFor="學生講座">
                <input
                  {...register("studentYear", { required: true })}
                  type="radio"
                  value="2"
                  name="studentYear"
                />
                中一
              </label>
            </span>
            {errors.studentYear && (
              <span className="input-error-message">*請選擇參加學生年級</span>
            )}
          </div>
          <div className="input-container">
            <div className="input-label-container">
              <div className="input-label">
                <span className="red-start">*</span>禮品包派發數量
              </div>
            </div>
            <input
              className="input-box"
              type="number"
              min="1"
              onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
              {...register("giftCount", {
                required: true,
                maxLength: 5,
                minLength: 1,
              })}
            />
            {errors.giftCount && (
              <span className="input-error-message">
                *請輸入有效禮品包派發數量
              </span>
            )}
          </div>
        </div>

        {/**參加活動*/}
        <div className="input-row">
          <div className="input-container">
            <div className="input-label-container">
              <div className="input-label">
                <span className="red-start">*</span>參加活動
              </div>
            </div>
            <label htmlFor="學生講座+護理包派發">
              <input
                {...register("event", { required: true })}
                type="radio"
                value="2"
                name="event"
                disabled
                // onClick={() => handleFormEvent(true)}
              />
              <span className="end-text">學生講座+護理包派發</span>{" "}
              <span className="red-start">名額已滿</span>
            </label>
            <label htmlFor="學生講座">
              <input
                {...register("event", { required: true })}
                type="radio"
                value="1"
                name="event"
                onClick={() => handleFormEvent(false)}
              />
              護理包派發
            </label>
            {errors.event && (
              <span className="input-error-message">*請選擇參加活動</span>
            )}
          </div>
          <div className="input-container"></div>
        </div>

        {/**參加講座之女生人數*/}
        {openFormEvent ? (
          <div className="input-row">
            <div className="input-container">
              <div className="input-label-container">
                <div className="input-label">
                  <span className="red-start">*</span>參加講座之女生人數
                </div>
              </div>
              <input
                className="input-box"
                type="number"
                min="1"
                onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                {...register("studentCount", {
                  required: true,
                  maxLength: 5,
                  minLength: 1,
                })}
              />
              {errors.studentCount && (
                <span className="input-error-message">
                  *請輸入有效參加講座之女生人數
                </span>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}

        {/**可參加活動日期及時間(請提供3個）*/}
        {openFormEvent ? (
          <div className="input-row">
            <div className="input-container date-time-container">
              <div className="input-label-container">
                <div className="input-label">
                  <span className="red-start">*</span>
                  申請活動日期及時間 <br></br>
                  <span className="s-font">
                    (申請人可提供三個日期及時間，以方便審核)
                    <br></br>
                    申請會以第一個申請日期及時間作為優先。
                  </span>
                </div>
              </div>

              <label
                className="input-date-time"
                htmlFor="可參加活動日期及時間A"
              >
                日期及時間A
                <input
                  {...register("eventDateTimeA", { required: isMustField })}
                  type="datetime-local"
                  name="eventDateTimeA"
                  min={tomorrowDate}
                  max="2025-08-31T23:59"
                />
              </label>
              <label
                className="input-date-time"
                htmlFor="可參加活動日期及時間B"
              >
                日期及時間B
                <input
                  {...register("eventDateTimeB", { required: false })}
                  type="datetime-local"
                  name="eventDateTimeB"
                  min={tomorrowDate}
                  max="2025-08-31T23:59"
                />
              </label>
              <label
                className="input-date-time"
                htmlFor="可參加活動日期及時間C"
              >
                日期及時間C
                <input
                  {...register("eventDateTimeC", { required: false })}
                  type="datetime-local"
                  name="eventDateTimeC"
                  min={tomorrowDate}
                  max="2025-08-31T23:59"
                />
              </label>

              {(errors.eventDateTimeA ||
                errors.eventDateTimeB ||
                errors.eventDateTimeC) && (
                <span className="input-error-message">
                  *請選擇參加活動日期及時間
                </span>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
        {/**如講座已滿額*/}
        {openFormEvent ? (
          <div className="input-row">
            <div className="input-container">
              <div className="input-label-container">
                <div className="input-label">
                  <span className="red-start">*</span>
                  如講座已滿額，貴校會否仍希望收到Kotex 高潔絲的護理包
                </div>
              </div>
              <span>
                <label htmlFor="是">
                  <input
                    {...register("eventIsFull", { required: isMustField })}
                    type="radio"
                    value={true}
                    name="eventIsFull"
                  />
                  是<span style={{ marginRight: "35px" }}></span>
                </label>
                <label htmlFor="否">
                  <input
                    {...register("eventIsFull", { required: isMustField })}
                    type="radio"
                    value={false}
                    name="eventIsFull"
                  />
                  否
                </label>
              </span>
              {errors.eventIsFull && (
                <span className="input-error-message">
                  *請選擇會否收到高潔絲的護理包
                </span>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
        {/**條款及細則*/}

        <div className="input-row">
          <div
            className="input-container"
            style={{ width: "100%", height: "70px" }}
          >
            <div className="input-label-container"></div>
            <span>
              <label htmlFor="是">
                <input
                  {...register("tnc", { required: true })}
                  type="radio"
                  value="tnc"
                  name="tnc"
                />
                <span className="red-start">*</span>
                本人同意高潔絲遵照
                <a
                  href="https://www.kimberly-clark.com/zh-tw/global-privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  私隱權政策
                </a>
                和
                <a
                  href="https://www.hk.kotex.com/zh-hk/terms-and-conditions"
                  target="_blank"
                  rel="noreferrer"
                >
                  條款及細則
                </a>
                收集及使用本人的個人及學校資料，用於登記及參與本次活動相關用途。
                <span style={{ marginRight: "35px" }}></span>
              </label>
            </span>
            {errors.tnc && (
              <span className="input-error-message">*請同意私隱政策</span>
            )}
          </div>
        </div>

        <div className="pre-submit-button-container">
          <div
            className="pre-submit-button"
            onClick={handleSubmit(handleDobuleConfirmSubmitEvent)}
          >
            提交表格
          </div>
        </div>
      </form>

      <div
        className="form-popup-container"
        style={isOpenPopup ? { display: "block" } : { display: "none" }}
      >
        <div className="form-popup">
          <div className="form-popup-content">
            {/**<div className="form-popup-text"></div> */}
            <div className="form-popup-button-container">
              <div
                className="form-popup-button"
                onClick={handleSubmit(onSubmit)}
              >
                確認提交
              </div>
              <div
                className="form-popup-button"
                onClick={() => handlePopupEvent(false)}
              >
                上一步
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className="loading-container" id="loading">
          <img
            className="loadingImage"
            alt="loading"
            src="./assets/loading.gif"
          ></img>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Form;
